/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import { useSelector } from 'react-redux';
// import NotePlate from 'components/MenuForDesktop/NotePlate';

import PHONELOGO from './phone-icon.png';
import KhpLogo from '../../assets/images/logo/logo-khaihoanprime.png';

import AquaMenuItem from './AquaMenuItem';
import {
  AquaMapfloorplanIconV2,
  // AquaRightArrow,
  // AquaGreenParkIcon,
  // AquaHouse,
  // AquaVilla,
  // AquaSceneMenuIcon,
  // AquaLargeMapfloorplanIcon,
  PrimeApartmentIcon,
  PrimeOfficeBuildingIcon,
} from 'components/icons/AquaIcons';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './style.scss';
// import HoveringPlate from 'components/HoveringPlate';
import CheckingSwitchDayTime from './CheckingSwitchDayTime';
import LevelSwitch from './LevelSwitch';

import { mikaSceneList, mikaTopMenu, sceneList } from './scene.helper';
import ScaleArrow from 'components/ScaleArrow';
import Compass from 'components/Compass';

const Icons = ({ iconOrder, isActive }) => {
  const iconComponents = [
    <AquaMapfloorplanIconV2 isActive={isActive} />,
    <PrimeOfficeBuildingIcon isActive={isActive} />,
    <PrimeApartmentIcon isActive={isActive} />,
  ];

  return <>{iconComponents[iconOrder]}</>;
};

const KhaiHoanPrimeLayout = ({
  desktop,
  mobile,
  scenes,
  // groups,
  onSelect,
  openMenu,
  handleOpenMenu,
  // activeColor,
  currentPano,
  menuList,
  // isSchoolLayout,
  tourLogo,
  isPrimeStyle,
  cameraParams,
  sceneRotation,
}) => {
  // const [selectedCard, setSelectedCard] = useState(null);
  const [hoverMenuItem, setHoverMenuItem] = useState(null);
  const [hoverScenes, setHoverScenes] = useState([]);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [isOpenTopviewSubmenu, setIsOpenTopviewSubmenu] = useState(false);
  const [isOpenMikaTopviewSubmenu, setIsOpenMikaTopviewSubmenu] =
    useState(false);
  // const [openRightMenu, setOpenRightMenu] = useState(false);

  const under1024 = useMemo(() => window.innerWidth < 1024, []);
  const isMobile = useMemo(() => window.innerWidth <= 767, []);

  // const { hasLogo } = useSelector((state) => ({
  //   logoURL: state.logoURL,
  //   hasLogo: state.hasLogo,
  // }));

  // const enableBranding = useSelector(
  //   ({ subscriptionPlan }) => subscriptionPlan.enableBranding
  // );

  const menuScenes = useMemo(() => {
    return menuList.flatMap((item) => item.scenes);
  }, [menuList]);

  useEffect(() => {
    if (hoverMenuItem === null) {
      setHoverScenes([]);
      return;
    }

    const menu = menuList[hoverMenuItem];

    if (menu.order === 0) {
      // menu đầu chỉ lấy 4 sub items

      setHoverScenes([...menu.scenes.slice(0, 4)]);
      return;
    }

    setHoverScenes([...menu.scenes.slice(0, 100)]);
  }, [hoverMenuItem, menuList, menuScenes]);

  const topviewSubmenu = useMemo(() => menuList[0].scenes.slice(4), [menuList]);

  const isTopViewScene = useMemo(
    () => sceneList.includes(currentPano?.id),
    [currentPano?.id]
  );

  const isMikaTopViewScene = useMemo(
    () => mikaSceneList.includes(currentPano?.id),
    [currentPano?.id]
  );

  return (
    <>
      <div className="khp-tour-logo__container">
        <a href="https://khaihoanprime.vn/" alt="go home">
          <div className="khp-tour-logo">
            <img alt="tour-logo" src={KhpLogo}></img>
          </div>
        </a>
      </div>

      <CheckingSwitchDayTime
        currentPano={currentPano}
        onSelect={onSelect}
        openMenu={openMenu}
      />

      <LevelSwitch
        currentPano={currentPano}
        onSelect={onSelect}
        openMenu={openMenu}
      />

      <Compass
        currentPano={currentPano}
        cameraParams={cameraParams}
        sceneRotation={sceneRotation}
      />

      {!under1024 ? (
        <div className="aqua-menu-wrapper">
          <div className="khp-tour-logo__container">
            <a href="https://khaihoanprime.vn/" alt="go home">
              <div className="khp-tour-logo">
                <img alt="tour-logo" src={KhpLogo}></img>
              </div>
            </a>
          </div>

          <div
            className={`tour-menu aqua-menu-gallery primeStyle ${
              desktop ? 'desktop' : ''
            } ${mobile ? 'mobile' : ''}  ${
              openMenu ? 'menu-open' : 'menu-close'
            }`}
          >
            <a href="https://khaihoanprime.vn/" alt="go home">
              <div className="school-layout__tour-logo">
                <img alt="tour-logo" src={tourLogo}></img>
              </div>
            </a>

            <div
              className={`menu-items menu-items__school-layout ${
                mobile ? 'menu-nonwrap' : ''
              }`}
            >
              <div>
                {menuList.map((item) => (
                  <div
                    key={item.order}
                    className="menu-items-aqua"
                    onMouseOver={() => setHoverMenuItem(item.order)}
                    onMouseLeave={() => setHoverMenuItem(null)}
                  >
                    <div
                      className="menu-items-aqua__container"
                      onClick={() =>
                        onSelect(item.scenes[0].groupId, item.scenes[0].id)
                      }
                    >
                      <p
                        className={`menu-items-aqua__title ${
                          hoverMenuItem === item.order ? 'hovering' : ''
                        }`}
                      >
                        {item.name}
                      </p>
                      <Icons
                        iconOrder={item.order}
                        isActive={hoverMenuItem === item.order}
                      />
                    </div>

                    <ScaleArrow fontSize={15} color={'white'} rotate={180} />

                    {hoverScenes?.length > 1 && (
                      <div
                        className={`menu-items-aqua__scenes ${
                          hoverMenuItem === item.order ? 'hovering' : ''
                        }`}
                      >
                        {hoverScenes.map((scene) => (
                          <React.Fragment key={scene.order}>
                            <AquaMenuItem
                              scene={scene}
                              scenes={scenes}
                              onSelect={onSelect}
                              currentPano={currentPano}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="menu-items-aqua__links">
                <div className="menu-items-aqua__links-container">
                  <a
                    className="menu-items-aqua__links-item-prime"
                    href="https://www.youtube.com/@khaihoanprime.official"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>

                  <a
                    className="menu-items-aqua__links-item-prime"
                    href="https://www.facebook.com/khaihoanprime"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>

                  <a
                    className="menu-items-aqua__links-item-prime"
                    href="https://www.tiktok.com/@khaihoanprime.official?_t=8l36y08avmk&_r=1"
                    target="_blank"
                  >
                    <i className="fab fa-tiktok"></i>
                  </a>
                  {/* <a
                    className="menu-items-aqua__links-item-prime"
                    href="tel:+84911806877"
                    target="_blank"
                  >
                    <img
                      src={PHONELOGO}
                      alt="tiktok-logo"
                      style={{ height: 23 }}
                    />
                  </a> */}
                </div>
              </div>

              <div className="menu-items-aqua__links-back-home">
                <a href="https://khaihoanprime.vn/" alt="go home">
                  <span className="menu-go-back-home">Trở về trang chủ</span>
                </a>
              </div>
            </div>

            <div
              className={`menu-toggle-prime menu-toggle-school__layout-dtwood ${
                !openMenu ? 'translate-right' : ''
              }`}
              onClick={handleOpenMenu}
            >
              <label
                className="menu-button-container school-layout__icon-container"
                htmlFor="menu-toggle"
              >
                <div className={`menu-button ${openMenu ? 'menu-open' : ''}`}>
                  <div className="menu-bar primeStyle"></div>
                  <div className="menu-bar primeStyle"></div>
                  <div className="menu-bar primeStyle"></div>
                </div>
              </label>
            </div>

            {!openMenu && (
              <div className="prime-menu-container">
                <p className="prime-menu-container__title">
                  {currentPano?.title == +currentPano?.title
                    ? 'CĂN HỘ MẪU'
                    : currentPano?.title}
                </p>
              </div>
            )}

            <div className="prime-reserve">
              <p>Created by VIZION + 2FVISUAL</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={`aquaMenu-mobile ${!openMobileMenu ? 'openMenu' : ''}`}>
          <div
            className={`aquaMenu-mobile-container__bottom-prime ${
              !openMobileMenu ? 'openPrimeMenu' : ''
            } ${isPrimeStyle ? 'primeStyle' : ''}`}
          >
            {tourLogo && (
              <div className="aquaMenu-mobile-container__prime-logo">
                <a href="https://khaihoanprime.vn/" alt="go home">
                  <div className="aquaMenu-mobile-container__prime-logo-C">
                    <img src={tourLogo} alt="prime-logo" />
                  </div>
                </a>
              </div>
            )}
            <div
              className={`aquaMenu-mobile-container__top-prime ${
                isPrimeStyle ? 'primeStyle' : ''
              }`}
            >
              <div className="aquaMenu-mobile-left__container">
                <div className="aquaMenu-mobile-left__title">
                  Created by VIZION + 2FVISUAL
                </div>
                <div
                  className={`menu-toggle-prime ${
                    !openMobileMenu ? 'background activeMenuToggle' : ''
                  } `}
                  onClick={() => {
                    setOpenMobileMenu(!openMobileMenu);
                    handleOpenMenu();
                  }}
                >
                  <label
                    className="menu-button-container school-layout__icon-container"
                    htmlFor="menu-toggle"
                  >
                    <div
                      className={`menu-button ${
                        openMobileMenu ? 'menu-open' : ''
                      }`}
                    >
                      <div className="menu-bar primeStyle"></div>
                      <div className="menu-bar primeStyle"></div>
                      <div className="menu-bar primeStyle"></div>
                    </div>
                  </label>
                </div>

                <div className="prime-menu-container">
                  <p
                    className="prime-menu-container__title"
                    onClick={() =>
                      isMobile &&
                      isTopViewScene &&
                      onSelect('welcome', 'topview-tang-27')
                    }
                  >
                    {currentPano?.title == +currentPano?.title
                      ? 'CĂN HỘ MẪU'
                      : isMobile && isTopViewScene
                      ? 'TOPVIEW'
                      : isMobile && isMikaTopViewScene
                      ? 'MIKA TOPVIEW'
                      : currentPano?.title}
                  </p>
                </div>

                <div className="mobile-banner"></div>
              </div>
            </div>

            <div
              className={`menu-items menu-items__school-layout primeStyle ${
                mobile ? 'menu-nonwrap' : ''
              }`}
            >
              <div>
                {menuList.map((item) => (
                  <div
                    key={item.order}
                    className="menu-items-aqua primeStyle"
                    onClick={() => {
                      if (
                        hoverMenuItem !== null &&
                        hoverMenuItem === item.order
                      ) {
                        setHoverMenuItem(null);
                      } else {
                        if (item.scenes.length > 1) {
                          setHoverMenuItem(item.order);
                          if (item.order !== 0) {
                            setIsOpenTopviewSubmenu(false);
                            setIsOpenMikaTopviewSubmenu(false);
                          }
                        } else if (item.scenes.length === 1) {
                          onSelect(item.scenes[0].groupId, item.scenes[0].id);
                          setOpenMobileMenu(false);
                        }
                      }
                    }}
                  >
                    <div
                      className={`menu-items-aqua__container ${
                        hoverMenuItem === item.order
                          ? 'background primeStyle'
                          : ''
                      }`}
                    >
                      <div className="menu-items-aqua__container-left">
                        <p
                          className={`menu-items-aqua__title primeStyle ${
                            hoverMenuItem === item.order ? 'hovering' : ''
                          }`}
                        >
                          {item.name}
                        </p>
                        <Icons
                          iconOrder={item.order}
                          isActive={hoverMenuItem === item.order}
                        />
                      </div>
                      <ScaleArrow
                        fontSize={12}
                        color={'white'}
                        rotate={hoverMenuItem === item.order ? -90 : 180}
                      />
                    </div>

                    <div
                      className={`menu-items-aqua__scenes ${
                        hoverMenuItem === item.order ? 'hovering' : ''
                      }`}
                    >
                      {hoverScenes.map((scene) => {
                        if (scene.id === 'mika-topview') return null;
                        return (
                          <React.Fragment key={scene.order}>
                            <AquaMenuItem
                              scene={scene}
                              scenes={scenes}
                              onSelect={onSelect}
                              currentPano={currentPano}
                              setOpenMobileMenu={() => setOpenMobileMenu(false)}
                              isOpenTopviewSubmenu={isOpenTopviewSubmenu}
                              setIsOpenTopviewSubmenu={setIsOpenTopviewSubmenu}
                              isOpenMikaTopviewSubmenu={
                                isOpenMikaTopviewSubmenu
                              }
                              setIsOpenMikaTopviewSubmenu={
                                setIsOpenMikaTopviewSubmenu
                              }
                            />
                          </React.Fragment>
                        );
                      })}

                      {isOpenTopviewSubmenu &&
                        topviewSubmenu.map((scene) => (
                          <React.Fragment key={scene.order}>
                            <AquaMenuItem
                              scene={scene}
                              scenes={scenes}
                              onSelect={onSelect}
                              currentPano={currentPano}
                              setOpenMobileMenu={() => setOpenMobileMenu(false)}
                              isOpenTopviewSubmenu={isOpenTopviewSubmenu}
                              setIsOpenTopviewSubmenu={setIsOpenTopviewSubmenu}
                              isTopviewSub={true}
                            />
                          </React.Fragment>
                        ))}

                      {hoverScenes.map((scene) => {
                        if (scene.id !== 'mika-topview') return null;
                        return (
                          <React.Fragment key={scene.order}>
                            <AquaMenuItem
                              scene={scene}
                              scenes={scenes}
                              onSelect={onSelect}
                              currentPano={currentPano}
                              setOpenMobileMenu={() => setOpenMobileMenu(false)}
                              isOpenTopviewSubmenu={isOpenTopviewSubmenu}
                              setIsOpenTopviewSubmenu={setIsOpenTopviewSubmenu}
                              isOpenMikaTopviewSubmenu={
                                isOpenMikaTopviewSubmenu
                              }
                              setIsOpenMikaTopviewSubmenu={
                                setIsOpenMikaTopviewSubmenu
                              }
                            />
                          </React.Fragment>
                        );
                      })}

                      {isOpenMikaTopviewSubmenu &&
                        mikaTopMenu.map((scene) => (
                          <React.Fragment key={scene.order}>
                            <AquaMenuItem
                              scene={scene}
                              scenes={scenes}
                              onSelect={onSelect}
                              currentPano={currentPano}
                              setOpenMobileMenu={() => setOpenMobileMenu(false)}
                              isOpenMikaTopviewSubmenu={
                                isOpenMikaTopviewSubmenu
                              }
                              setIsOpenMikaTopviewSubmenu={
                                setIsOpenMikaTopviewSubmenu
                              }
                              isTopviewSub={true}
                            />
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="menu-items-aqua__links">
                <div className="menu-items-aqua__links-container">
                  <a
                    className="menu-items-aqua__links-item-prime"
                    href="https://www.youtube.com/@khaihoanprime.official"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>

                  <a
                    className="menu-items-aqua__links-item-prime"
                    href="https://www.facebook.com/khaihoanprime"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>

                  <a
                    className="menu-items-aqua__links-item-prime"
                    href="https://www.tiktok.com/@khaihoanprime.official?_t=8l36y08avmk&_r=1"
                    target="_blank"
                  >
                    <i className="fab fa-tiktok"></i>
                  </a>
                  {/* <a
                    className="menu-items-aqua__links-item-prime"
                    href="tel:+84911806877"
                    target="_blank"
                  >
                    <img
                      src={PHONELOGO}
                      alt="tiktok-logo"
                      style={{ height: 23 }}
                    />
                  </a> */}
                </div>
              </div>

              <div className="menu-items-aqua__links-back-home">
                <a href="https://khaihoanprime.vn/" alt="go home">
                  <span className="menu-go-back-home">Trở về trang chủ</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KhaiHoanPrimeLayout;
