import * as THREE from 'three';

export const getCameraPositionFromLookAtCoordinates = (x, y, z) => {
  let newX = x,
    newY = y,
    newZ = z;
  while (Math.abs(newX) >= 10 || Math.abs(newY) >= 10 || Math.abs(newZ) >= 10) {
    newX = newX / 2;
    newY = newY / 2;
    newZ = newZ / 2;
  }
  return {
    x: -1 * newX,
    y: -1 * newY,
    z: -1 * newZ,
  };
};

export function getDotProductTwoVectors(vec1, vec2) {
  return vec1.x * vec2.x + vec1.y * vec2.y + vec1.z * vec2.z;
}

export function getCrossProductTwoVectors(vec1, vec2) {
  return {
    x: vec1.y * vec2.z - vec1.z * vec2.y,
    y: vec1.z * vec2.x - vec1.x * vec2.z,
    z: vec1.x * vec2.y - vec1.y * vec2.x,
  };
}

export function cosTwoVectors(a, b) {
  return (
    (a.x * b.x + a.y * b.y + a.z * b.z) /
    (Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2) + Math.pow(a.z, 2)) *
      Math.sqrt(Math.pow(b.x, 2) + Math.pow(b.y, 2) + Math.pow(b.z, 2)))
  );
}

export function calcAngleBetweenVectors(a, b) {
  const cosAngle = cosTwoVectors(a, b);
  const angle = Math.acos(cosAngle);
  return angle;
}

export const updateVector3OrPointByRotationXZ = (
  pointArr,
  rad,
  isVec3 = true
) => {
  const [x, y, z] = pointArr;
  const x_new = x * Math.cos(rad || 0) + z * Math.sin(rad || 0);
  const z_new = z * Math.cos(rad || 0) - x * Math.sin(rad || 0);
  if (isVec3) {
    return new THREE.Vector3(x_new, 0, z_new);
  } else {
    return [x_new, y, z_new];
  }
};

export const getSphericalPosition = function (x, y, w, h) {
  const u = x / w;
  const v = y / h;
  const theta = u * 2 * Math.PI;
  const phi = v * Math.PI;
  return new THREE.Vector3(
    Math.cos(theta) * Math.sin(phi),
    Math.sin(theta) * Math.sin(phi),
    Math.cos(phi)
  );
};

window.getSphericalPosition = getSphericalPosition;

export const updatePositionOfHotspots = (hotspots, camera, width, height) => {
  return hotspots.map((hotspot) => ({
    ...hotspot,
    position: !hotspot.location
      ? {
          visible: false,
          x: -1000,
          y: -1000,
        }
      : get2DScreenPosition(hotspot.location, camera, width, height),
  }));
};

export const get2DScreenPosition = (coord, camera, width, height) => {
  if (!(coord && camera && width && height)) {
    return {
      visible: false,
      x: -1000,
      y: -1000,
    };
  }
  const hpLocation = new THREE.Vector3(coord.x, coord.y, coord.z);
  const vector = hpLocation.project(camera);
  return {
    visible: Math.abs(vector.z) <= 1,
    x: ((vector.x + 1) / 2) * width,
    y: (-(vector.y - 1) / 2) * height,
  };
};

export const distanceBetween2dCoordinates = (
  coord1 = { x: 0, y: 0 },
  coord2 = { x: 0, y: 0 }
) => {
  const xDiff = Math.abs(coord1.x - coord2.x),
    yDiff = Math.abs(coord1.y - coord2.y);
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

export const isPositionValid = (position) => {
  if (!position || !Array.isArray(position)) return false;
  const [x, y, z] = position;
  if (x === null || typeof x === 'undefined') return false;
  if (y === null || typeof y === 'undefined') return false;
  if (z === null || typeof z === 'undefined') return false;
  return true;
};

export function calRotationYDefaultAndCenterLookAt(
  defaultOrientation,
  lookAtPoint
) {
  const vec1 = { x: -defaultOrientation[0], y: 0, z: -defaultOrientation[2] };
  const vec2 = { x: -lookAtPoint[0], y: 0, z: -lookAtPoint[2] };
  const angle = calcAngleBetweenVectors(vec1, vec2);
  const crossProduct = getCrossProductTwoVectors(vec1, vec2);
  const dotProduct = getDotProductTwoVectors(vec1, vec2);
  let angleInClockwise;
  if (crossProduct.y < 0) {
    angleInClockwise = angle;
  } else if (crossProduct.y > 0) {
    angleInClockwise = 2 * Math.PI - angle;
  } else if (crossProduct.y === 0) {
    angleInClockwise = dotProduct > 0 ? 0 : Math.PI;
  }
  return angleInClockwise;
}
